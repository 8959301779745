export const reviewArr = [
  {
    reviewer: "Johnny",
    body: "Best D.J, I jammmed and partied all night!",
    event: "Party",
  },
  {
    reviewer: "Quin",
    body: "The quality and the customer service is top notch. Very affordable prices. Highly recommend. ",
    event: "Wedding",
  },
  {
    reviewer: "Jackie",
    body: "Great prices, great entertainment and on time!. I call Rob anytime I host a music event in Kansas City",
    event: "Music event",
  },
];
